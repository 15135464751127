@font-face {
	font-display: swap;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	src: local(""), url("../fonts/montserrat-v25-latin-regular.woff2") format("woff2"),
		url("../fonts/montserrat-v25-latin-regular.woff") format("woff");
}
@font-face {
	font-display: swap;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	src: local(""), url("../fonts/montserrat-v25-latin-500.woff2") format("woff2"),
		url("../fonts/montserrat-v25-latin-500.woff") format("woff");
}
@font-face {
	font-display: swap;
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 700;
	src: local(""), url("../fonts/montserrat-v25-latin-700.woff2") format("woff2"),
		url("../fonts/montserrat-v25-latin-700.woff") format("woff");
}
@font-face {
	font-display: swap;
	font-family: "Open Sans";
	font-style: normal;
	font-weight: 400;
	src: local(""), url("../fonts/open-sans-v29-latin-regular.woff2") format("woff2"),
		url("../fonts/open-sans-v29-latin-regular.woff") format("woff");
}
@font-face {
	font-display: swap;
	font-family: "Open Sans";
	font-style: normal;
	font-weight: 600;
	src: local(""), url("../fonts/open-sans-v29-latin-600.woff2") format("woff2"),
		url("../fonts/open-sans-v29-latin-600.woff") format("woff");
}
@font-face {
	font-display: swap;
	font-family: "Open Sans";
	font-style: italic;
	font-weight: 400;
	src: local(""), url("../fonts/open-sans-v29-latin-italic.woff2") format("woff2"),
		url("../fonts/open-sans-v29-latin-italic.woff") format("woff");
}
@font-face {
	font-display: swap;
	font-family: "Open Sans";
	font-style: italic;
	font-weight: 600;
	src: local(""), url("../fonts/open-sans-v29-latin-600italic.woff2") format("woff2"),
		url("../fonts/open-sans-v29-latin-600italic.woff") format("woff");
}
@font-face {
	font-display: swap;
	font-family: "Open Sans";
	font-style: normal;
	font-weight: 700;
	src: local(""), url("../fonts/open-sans-v29-latin-700.woff2") format("woff2"),
		url("../fonts/open-sans-v29-latin-700.woff") format("woff");
}
