.mco-search-results__item {
	background: #ffffff !important;
	box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.12), 10px 32px 72px rgba(0, 0, 0, 0.08);
}
.mco-search-results__item-title {
	color: var(--mco-palette-primary-main) !important;
}
.mco-search-results__item-title:hover {
	color: var(--mco-palette-primary-dark) !important;
}
