/* Blog - Teaser */

.mco-view-component-container-mco-blog-teaser {
	padding-bottom: 80px !important;
	padding-top: 80px !important;
	position: relative;
}
.mco-view-component-container-mco-blog-teaser::before {
	background: #f7f7f8;
	bottom: 0;
	content: "";
	left: -50%;
	position: absolute;
	right: 0;
	top: 0;
	width: 5000px;
	z-index: -1;
}
.mco-blog-teaser {
	position: relative;
}
.mco-blog-teaser h2 {
	padding-bottom: 16px;
	position: relative;
}
.mco-blog-teaser h2::after {
	background: linear-gradient(270deg, #c65470 0%, #548eb5 100%);
	bottom: 0;
	content: "";
	height: 2px;
	left: 0;
	position: absolute;
	width: 56px;
}
.mco-blog-teaser .mco-blog-teaser-item {
	box-shadow: none !important;
	border-radius: 4px;
	/* transition: box-shadow 250ms ease; */
}
.mco-blog-teaser .mco-blog-teaser-item__title {
	color: var(--mco-palette-primary-main) !important;
}
.mco-blog-teaser .mco-blog-teaser-item__text {
	margin-top: 0 !important;
}
.mco-blog-teaser .mco-blog-teaser-item__info-date {
	background: var(--mco-palette-background-light) !important;
	color: var(--mco-palette-text-primary) !important;
}
.mco-blog-teaser .mco-blog-teaser-item__info-label {
	background: var(--mco-palette-primary-main) !important;
}
.mco-blog-teaser .mco-blog-teaser-item__categories {
	color: var(--mco-palette-text-primary) !important;
}
.mco-blog-teaser .mco-blog-teaser-item__author {
	color: var(--mco-palette-primary-main) !important;
}

@media (min-width: 600px) {
	.mco-blog-teaser h2 {
		padding-right: 150px;
	}
	.mco-blog-teaser .mco-blog-teaser-item {
		box-shadow: 0px 0px 1px rgb(0 0 0 / 12%), 8px 16px 32px rgb(0 0 0 / 8%);
	}
	.mco-blog-teaser .mco-blog-teaser-item:hover {
		box-shadow: 0px 0px 1px rgb(0 0 0 / 12%), 8px 16px 32px rgb(0 0 0 / 20%);
	}
	.mco-blog-teaser .mco-blog-teaser__button {
		position: absolute;
		margin: 0;
		right: 0;
		top: 0;
	}
}
