.mco-singleimage-01__content {
	padding: 24px 0 0 !important;
}
.mco-singleimage-01__content-text {
	text-align: center !important;
}
.mco-singleimage-04__content {
	max-width: 1600px !important;
}
.mco-singleimage-04 .mco-singleimage-04__content-wrapper {
	background: #ffffff;
	box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.12), 10px 32px 72px rgba(0, 0, 0, 0.08);
	border-radius: 4px;
}
.mco-singleimage-04 .mco-singleimage-04__content-title {
	color: var(--mco-palette-primary-main);
}
.mco-singleimage-04 .mco-singleimage-04__content-text {
	color: var(--mco-palette-text-primary);
}
