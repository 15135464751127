.mco-table-01__headline {
	margin-bottom: 32px !important;
	padding-bottom: 18px;
	position: relative;
	text-align: center;
}
.mco-table-01__headline::after {
	background: linear-gradient(270deg, #c65470 0%, #548eb5 100%);
	bottom: 0;
	content: "";
	height: 2px;
	left: 50%;
	margin-left: -28px;
	position: absolute;
	width: 56px;
}
.mco-table-01__cell {
	border-bottom: none !important;
	box-shadow: inset 0px -1px 0px #999999;
	font-family: "Open Sans" !important;
	font-weight: 400 !important;
	padding: 8px !important;
}
th.mco-table-01__cell {
	background: none !important;
	color: var(--mco-palette-primary-main) !important;
	font-size: 18px !important;
	padding: 13.5px 8px !important;
}
.mco-table-01__swipePopUp {
	background: #333333 !important;
}
