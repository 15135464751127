.mco-list-01__headline {
	margin-bottom: 32px !important;
}
.mco-list-01 .MuiGrid-root {
	row-gap: 8px;
}
.mco-list-01__list-item svg {
	margin-right: 12px !important;
	margin-top: 2px;
}
.mco-view-component-container-basic-list-variant--2 .mco-list-01__list {
	counter-reset: line-number;
}
.mco-view-component-container-basic-list-variant--2 .mco-list-01__list-item {
	counter-increment: line-number;
}
.mco-view-component-container-basic-list-variant--2 .mco-list-01__list-item::before {
	color: var(--mco-palette-primary-main);
	content: counter(line-number);
	display: flex;
	font-family: "Open Sans";
	font-size: 18px;
	margin-right: 8px;
	margin-top: 2px;
	min-width: 24px;
}
.mco-view-component-container-basic-list-variant--2 .mco-list-01__list-item > div:first-child {
	display: none !important;
}
