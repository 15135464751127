.mco-view-component-container-extension-store-locator {
	margin-top: 0 !important;
	padding-top: 0 !important;
}
.mco-store-locator .mco-store-locator__container {
	max-width: 1328px;
}
.mco-store-locator .mco-store-locator-map {
	height: 50vh;
	min-height: 330px;
}
.mco-store-locator .mco-store-locator-map .leaflet-tile-container img {
	filter: grayscale(1);
}
.mco-store-locator .mco-store-locator-headline span {
	color: var(--mco-palette-primary-main);
}

@media (min-width: 768px) {
	.mco-store-locator .mco-store-locator-list-item__contact {
		position: relative;
	}
	.mco-store-locator .mco-store-locator-list-item__contact::before {
		background: linear-gradient(180deg, #c65470 0%, #548eb5 100%);
		bottom: 0;
		content: "";
		left: -24px;
		position: absolute;
		top: 0;
		width: 2px;
	}
}

@media (min-width: 960px) {
	.mco-store-locator .mco-store-locator-map {
		height: 650px;
	}
}
