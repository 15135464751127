h2.mco-headline {
	padding-bottom: 18px;
	position: relative;
	text-align: center;
}
h2.mco-headline::after {
	background: linear-gradient(270deg, #c65470 0%, #548eb5 100%);
	bottom: 0;
	content: "";
	height: 2px;
	left: 50%;
	margin-left: -28px;
	position: absolute;
	width: 56px;
}
.mco-view-component-container-basic-headline--variant-7 {
	padding-bottom: 56px !important;
	padding-top: 56px !important;
	position: relative;
}
.mco-view-component-container-basic-headline--variant-7::before {
	background: linear-gradient(180deg, #c65470 0%, #548eb5 100%);
	bottom: 0;
	content: "";
	left: 50%;
	margin-left: -1px;
	position: absolute;
	top: 0;
	width: 2px;
	z-index: -1;
}
.mco-view-component-container-basic-headline--variant-7 h1 {
	background: #ffffff;
	padding-bottom: 8px !important;
	padding-top: 8px !important;
	text-align: center;
}
