/* Headline */

.mco-view-component-container-basic-headline + .mco-view-component-container-basic-headline {
	margin-top: -70px;
}

/* Headline & Text */

.mco-view-component-container-basic-headline + .mco-view-component-container-basic-text {
	margin-top: -48px;
}

/* Headline & Galler */

.mco-view-component-container-basic-headline + .mco-view-component-container-basic-gallery {
	margin-top: -24px;
}

/* Videos */

.mco-view-component-container-basic-video + .mco-view-component-container-basic-video {
	margin-top: -80px;
}

/* Dropdown */

.mco-view-component-container-basic-dropdown-group
	+ .mco-view-component-container-basic-dropdown-group {
	margin-top: -80px;
}

/* Background - Headline - Dropdown */

/* .mco-view-component-container-extension-background-group
	> div
	> .mco-view-component-container-basic-headline:first-child {
	max-width: 1112px !important;
} */
.mco-view-component-container-extension-background-group
	> div
	> .mco-view-component-container-basic-headline:first-child
	h2 {
	padding-bottom: 0 !important;
	text-align: left !important;
}
.mco-view-component-container-extension-background-group
	> div
	> .mco-view-component-container-basic-headline:first-child
	h2::after {
	display: none !important;
}
.mco-view-component-container-extension-background-group
	.mco-view-component-container-basic-headline
	+ .mco-view-component-container-basic-dropdown-group {
	margin-top: -48px;
}
